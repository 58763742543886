import React, { lazy } from 'react'
import { Navigate, RouteObject } from 'react-router-dom'

const Layout = lazy(
  () => import(/* webpackChunkName: "Storybook" */ '@pages/storybook')
)
const Home = lazy(
  () =>
    import(
      /* webpackChunkName: "Storybook.Home" */ '@pages/storybook/pages/home'
    )
)
const Preview = lazy(
  () =>
    import(
      /* webpackChunkName: "Storybook.preview" */ '@pages/storybook/pages/preview'
    )
)
const Sheet = lazy(
  () =>
    import(
      /* webpackChunkName: "Storybook.preview" */ '@/pages/storybook/layout'
    )
)
const routes: RouteObject[] = [
  {
    path: '/storybook',
    element: <Layout />,
    children: [
      {
        index: true,
        element: <Navigate to={'/storybook/home'} />
      },
      {
        path: 'home',
        element: <Home />
      },
      {
        path: 'preview',
        element: <Preview />
      },
      {
        path: 'Sheet',
        element: <Sheet />
      }
    ]
  }
]
export default routes
