import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import useBoolean from '@vezham/ui/dist/contracts/hooks/useBoolean'
import useVStore from '@vezham/ui/dist/contracts/hooks/useVStore'
import { HQkeys, StateKeys, TokenKeys } from '@vezham/ui/dist/contracts/iam'
import { useTheme } from '@vezham/ui/dist/contracts/theme'
import { Vcolor, Vmode } from '@vezham/ui/dist/contracts/theme/interface'
import '@vezham/ui/dist/index.css'
import Loading from '@vezham/ui/dist/organisms/loading'

import { people as usePeople } from '@store/hq/people/actions'

import { saveAndSendAccountDCToIAM } from '@utils/iam/onMessage/token'

import '@/App.less'
import { AppRoutes, Routers } from '@/routes'

function App() {
  const searchParams = new URLSearchParams(window.location.search)
  const state = searchParams.get(StateKeys.KEY)

  const navigate = useNavigate()
  const { value: isLoading, setValue } = useBoolean(true)
  const { setMode, setColor } = useTheme()

  const init = async () => {
    const RTaccount = useVStore.get(TokenKeys.ACCOUNT_REFRESH_TOKEN)
    if (RTaccount) {
      await usePeople
        .profile({})
        .then(people => {
          useVStore.set(HQkeys.ACTIVE_PEOPLE, people.handle)
          saveAndSendAccountDCToIAM(people.data_center) // wjdlz | TODO: remove it post workspace profile api

          if (state == StateKeys.NO_ACTIVE_IAM_ACCOUNT) {
            handleNoActiveIAMaccount(RTaccount)
          } else {
            setValue(false)
          }
          return
        })
        .catch(err => {
          console.log('accounts | err: ' + err)
          // useVStore.delete(TokenKeys.ACCOUNT_REFRESH_TOKEN)
          navigate(AppRoutes.LogIn + `?state=no_active_account`)
          return
        })
    } else {
      navigate(AppRoutes.Welcome)
      setValue(false)
      return
    }
  }

  const handleNoActiveIAMaccount = (code: string) => {
    window.open(
      process.env.REACT_APP_BUSINESS_URL +
        `?${StateKeys.KEY}=${StateKeys.INIT_IAM_ACCOUNT_BY_CODE}&${StateKeys.INIT_IAM_ACCOUNT_BY_CODE}=${code}`,
      '_self'
    )
  }

  useEffect(() => {
    init()
  }, [])

  // wjdlz | TODO: have to update it based on API response
  useEffect(() => {
    setMode(Vmode.SYSTEM)
    setColor(Vcolor.BLUE)
  }, [])

  return <>{isLoading ? <Loading /> : <Routers />}</>
}

export default App
